<template>
  <v-container fluid tag="section" class="pt-3 px-8 pb-8">
    <v-row>
      <v-col cols="12" class="pt-8 pl-3 pr-3 pb-8">
        <p class="text-h5 main-font-bold">
          <span class="pr-5">{{ $t('benefit_partners_title') }}</span>
        </p>
        <p class="text-subtitle-1">{{ $t('benefit_partners_subtitle') }}</p>
        <v-row>
          <v-col cols="12" sm="6" md="3" v-for="(partner, index) in benefitPartners" :key="index">
            <v-hover v-slot="{ hover }">
              <v-card
                  v-if="partner.target"
                  class="d-flex justify-center flex-column"
                  height="100%"
                  :elevation="hover ? 7 : 5"
                  @click="openBenefitPartnerDialog(partner)"
              >
                <div class="pb-4 pl-4 pr-4 pt-6" v-if="partner.logo && partner.name">
                  <v-img
                      :src="partner.logo"
                      :alt="partner.name"
                      :max-height="60"
                      :contain="true"
                  />
                </div>
                <v-card-text class="text--primary text-center">
                  <v-card-title class="mb-2 pa-0 justify-center font-weight-bold" v-if="partner.title">{{ partner.title }}</v-card-title>
                  <p class="ma-0">{{ partner.description }}</p>
                </v-card-text>
              </v-card>
              <v-card
                  v-else
                  class="d-flex justify-center flex-column"
                  height="100%"
                  :elevation="5"
              >
                <div class="pb-4 pl-4 pr-4 pt-6" v-if="partner.logo && partner.name">
                  <v-img
                      :src="partner.logo"
                      :alt="partner.name"
                      :max-height="60"
                      :contain="true"
                  />
                </div>
                <v-card-text class="text--primary text-center">
                  <v-card-title class="mb-2 pa-0 justify-center font-weight-bold" v-if="partner.title">{{ partner.title }}</v-card-title>
                  <p class="ma-0">{{ partner.description }}</p>
                </v-card-text>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <NoSubscriptionDialog/>
    <v-dialog
        v-model="benefitPartnerDialog"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="benefitPartnerDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text--primary text-center">
          <img
              v-if="currentPartner.logo && currentPartner.name"
              :src="currentPartner.logo"
              :alt="currentPartner.name"
              class="mb-2"
              :width="180"
          />
          <div class="text-subtitle-1 mb-6">{{ currentPartner.long_description || currentPartner.description }}</div>
          <v-text-field
              v-model="couponText"
              id="coupon-code"
              color="primary"
              readonly
              class="flex-grow-1 mb-3"
              :class="{'no-coupon-code-needed': !currentPartner.coupon}"
              :append-icon="currentPartner.coupon ? 'mdi-content-copy' : ''"
              @click:append="currentPartner.coupon ? copyToClipboard(currentPartner.coupon) : null"
              outlined
              hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-0 pr-6 pl-6 pb-6">
          <v-btn
              v-if="currentPartner.target"
              large
              block
              color="primary"
              :href="currentPartner.target"
              @click="currentPartner.coupon ? copyToClipboard(currentPartner.coupon) : null"
              target="_blank"
          >
            <span class="white--text">
            {{ $t('go_to_benefit_partner') }}
            <v-icon>mdi-chevron-right</v-icon>
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import NoSubscriptionDialog from "@/dialogs/NoSubscriptionDialog.vue";
import store from "@/store";

export default {
  components: {NoSubscriptionDialog},
  data() {
    return {
      benefitPartnerDialog: false,
      currentPartner: {},
      benefitPartners: [
        {
          target: 'https://geras24.juradirekt.com',
          name: this.$t('benefit_partners_list.juradirekt.name'),
          description: this.$t('benefit_partners_list.juradirekt.description'),
          long_description: this.$t('benefit_partners_list.juradirekt.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/juradirekt.png`
        },
        {
          target: 'https://safe-id.eu/discount/alleato',
          name: this.$t('benefit_partners_list.saveid.name'),
          description: this.$t('benefit_partners_list.saveid.description'),
          long_description: this.$t('benefit_partners_list.saveid.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/safeid.png`
        },
        {
          target: 'https://life4.style/',
          name: this.$t('benefit_partners_list.life4style.name'),
          description: this.$t('benefit_partners_list.life4style.description'),
          long_description: this.$t('benefit_partners_list.life4style.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/life4style.png`,
          coupon: 'alleato'
        },
        {
          target: 'https://www.deinsportsfreund.de/',
          name: this.$t('benefit_partners_list.dsf.name'),
          description: this.$t('benefit_partners_list.dsf.description'),
          long_description: this.$t('benefit_partners_list.dsf.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/dsf.png`,
          coupon: 'alleato'
        },
        {
          target: 'https://www.wechselpilot.com/?refId=geras24-gmbh%2Bidecb2c25e-3386-4274-8b8e-a9fd294c1ac3/',
          name: this.$t('benefit_partners_list.wechselpilot.name'),
          description: this.$t('benefit_partners_list.wechselpilot.description'),
          long_description: this.$t('benefit_partners_list.wechselpilot.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/wechselpilot.png`,
        },
        {
          target: 'https://www.quarg.net/',
          name: this.$t('benefit_partners_list.quarg.name'),
          description: this.$t('benefit_partners_list.quarg.description'),
          long_description: this.$t('benefit_partners_list.quarg.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/quarg.png`,
          coupon: 'Per Telefon: +49 211 56660 600',
        },
        {
          target: 'https://www.eventhotels.com/geras24/',
          name: this.$t('benefit_partners_list.eventhotels.name'),
          description: this.$t('benefit_partners_list.eventhotels.description'),
          long_description: this.$t('benefit_partners_list.eventhotels.long_description'),
          logo: `${store.getters.frontUrl}/assets/img/home/eventhotels.png`,
        },
        {
          title: this.$t('benefit_partners_list.carclub.name'),
          description: this.$t('benefit_partners_list.carclub.description'),
        },
        {
          title: this.$t('benefit_partners_list.toppartner.name'),
          description: this.$t('benefit_partners_list.toppartner.description'),
        },
        {
          title: this.$t('benefit_partners_list.sportclub.name'),
          description: this.$t('benefit_partners_list.sportclub.description'),
        },
      ],
    }
  },
  computed: {
    couponText() {
      return this.currentPartner.coupon || this.$t('benefit_partners_no_coupon');
    }
  },
  methods: {
    openBenefitPartnerDialog(partner) {
      this.currentPartner = partner;
      this.benefitPartnerDialog = true;
    },
    async copyToClipboard(coupon) {
      try {
        await navigator.clipboard.writeText(coupon);
        this.$helpers.showMessage('success', this.$t('coupon_code_copied'));
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
  }
}
</script>

export default {
    login: "Login",
    admin_login: "Admin Login",
    email: "E-Mail-Adresse",
    password: "Passwort",
    safety: "Sicherheit",
    google: "Google",
    facebook: "Facebook",
    create_account: "Registrieren",
    no_account: "Noch kein Konto? ",
    create_account_for_free: "Kostenlos registrieren",
    for_personal_or_business: "für privat oder geschäftlich",
    forget_password: "Passwort vergessen?",
    success_verify: "Dein Konto wurde erfolgreich verifiziert! Du kannst dich jetzt einloggen!",
    success_login: "Willkommen bei Geras24!",
    enter_email: "Bitte gib deine E-Mail-Adresse ein!",
    email_not_valid: "Die eingegebene E-Mail-Adresse ist ungültig!",
    enter_password: "Bitte gib dein Passwort ein!",
    password_rules: {
        min_length: "Mindestens 8 Zeichen", uppercase: "Mindestens ein Großbuchstabe", lowercase: "Mindestens ein Kleinbuchstabe", number: "Mindestens eine Zahl", special: "Mindestens ein Sonderzeichen"
    },
    password_not_valid: "Das eingegebene Passwort entspricht nicht den Mindestanforderungen!",
    register: "Registrieren",
    register_go_to_shop: 'Registrieren und weiter zum Shop',
    register_to_shop: 'Zum Shop',
    register_book: "Mitgliedschaft buchen",
    competition_participation: "Gewinnspiel Teilnahme",
    register_compete: "Registrieren und teilnehmen",
    register_compete_description: "Registriere dich jetzt auf Geras24 und nehme automatisch an unserem Gewinnspiel teil. Wir wünschen dir viel Glück!",
    register_book_selected: 'Deine gewählte Mitgliedschaft:',
    register_book_selected_description: 'Nach der Registrierung wirst du zu unserem Shop weitergeleitet. Bei erfolgreicher Bezahlung erhältst du einen Freischaltcode. Nach dem du dein Konto erfolgreich bestätigt hast, kannst du dich einloggen und den Code eingeben um deine Mitgliedschaft freizuschalten.',
    register_description: "Nach der Registrierung erhältst du eine E-Mail mit einem Bestätigungslink. Nach dem du dein Konto erfolgreich bestätigt hast, kannst du dich einloggen und die kostenlosen Funktionen von Geras24 nutzen.",
    register_book_logged_in: 'Buche bitte deine Mitgliedschaft in unserem Shop. Bei erfolgreicher Bezahlung erhältst du einen Freischaltcode den du in deinem Konto einlösen kannst.',
    register_go_to_privacy: 'Zu den Datenschutzbestimmungen',
    register_go_to_terms: 'Zu den Verkaufsbedingungen',
    register_privacy: 'Datenschutzbestimmungen',
    register_terms: 'Verkaufsbedingungen',
    register_i_accept: ' Ich habe die',
    register_and: 'und die',
    register_noted: 'zur Kenntnis genommen.',
    login_not_confirmed: 'Du hast deine E-Mail-Adresse noch nicht verifiziert. Wir haben dir eine neue E-Mail mit dem Bestätigungslink zugesendet.',
    name: "Name",
    first_name: "Vorname",
    last_name: "Nachname",
    username: "Benutzername",
    password_confirm: "Passwort bestätigen",
    referral_code: "Referenzcode",
    with_your_account: "mit deinem Konto",
    enter_firstname: "Bitte gib deinen Vornamen ein!",
    enter_name: "Bitte gib deinen Nachnamen ein!",
    enter_password_confirm: "Bitte Passwort bestätigen!",
    accept_terms: "Bitte Datenschutz bestätigen!",
    password_confirm_error: "Passwort und Bestätigung stimmen nicht überein!",
    register_send_activation_email: "Bitte klick auf den Aktivierungscode den wir dir per E-Mail gesendet haben!",
    new_password: "Neues Passwort",
    confirm_new_password: "Neues Passwort bestätigen",
    password_hint: "Mindestens 8 Zeichen",
    password_changed: "Dein Passwort wurde erfolgreich geändert!",
    profile_updated: "Dein Profil wurden erfolgreich aktualisiert!",
    user_not_verified: "Benutzer nicht verifiziert!",
    reset_password: "Passwort zurücksetzen",
    reset_password_description: "Bitte gib deine E-Mail-Adresse ein, um dein Passwort zurückzusetzen. Wir senden dir anschließend eine E-Mail mit einem Link, um dein Passwort zu ändern.",
    reset_password_success: "Bitte klicke auf den Wiederherstellungslink, den wir dir per E-Mail gesendet haben!",
    activate_abo: "Abo aktivieren",

    checklist_title: "Ihre persönliche Checkliste",
    checklist_subtitle: "Wenn ein Familienmitglied stirbt, müssen trotz der Trauer viele bürokratische Angelegenheiten geklärt werden. In dieser emotionalen Ausnahmesituation droht die Bürokratie uns zu überfordern.",
    checklist_checkliste: "Checkliste",
    checklist_meldepflichtig: "Meldepflichtig",
    checklist_frist_nach_todesfall: "Frist nach Todesfall",
    checklist_besonderheiten: "BESONDERHEITEN",
    checklist_notizen: "Notizen",
    checklist_versicherungen: "Versicherungen",
    checklist_mitgliedschaften: "Mitgliedschaften",
    collections_botschaften_title: "Persönliche Botschaften",
    collections_botschaften_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",

    cancel: "Abbrechen",
    save: "Speichern",
    update: "Aktualisieren",
    photos: "Fotos",
    documents: "Briefe",
    videos: "Videos",
    audios: "Sprachnachrichten",
    create_new_category: "Kategorie hinzufügen",
    update_category: "Kategorie aktualisieren",
    create_first_category: "Erste Kategorie hinzufügen",
    play: "Play",
    description: "Beschreibung",
    size: "Größe",
    date: "Datum",
    actions: "Aktionen",

    upload_new_file: "Laden Sie hier weitere Dokumente hoch",
    are_you_sure: "Sind Sie sicher?",
    delete_file_alert: "Nach dem Löschen ist die Datei unwiederbringlich verloren.",
    delete_category_alert: "Durch das Löschen der Kategorie werden alle darin enthaltenen Dateien gelöscht und können nicht mehr wiederhergestellt werden.",
    category_name: "Kategoriename",
    yes: "Ja",
    no: "Nein",
    later: "Später",
    buy_now: "Zu den Mitgliedschaften",
    buy: "Kaufen",
    need_payment: "Keine Mitgliedschaft vorhanden",
    need_payment_desc: "Um auf diesen Bereich zugreifen zu können, müssen Sie eine Mitgliedschaft abschließen.",

    upload_new_media_subtitle: "Dateiformate: JPG, JPEG, PNG, BMP, GIF, SVG, AVI, MP4, MKV, FLV, WEBM, MOV, WMV, MP3, WAV, M4A, OGG",
    upload_new_document_subtitle: "Dateiformate: TXT, DOC, DOCX, PDF, ZIP, XLSX, PPT, PPTX",
    uploading: "Uploading ... bitte warten",
    upload: "Hochladen",
    dont_close_page: "Bitte die Seite nicht schliessen",
    file: "Datei",
    new_file: "Neue Datei",
    select_file: "Bitte Dateien wählen",
    file_desc: "Datei Bezeichnung",
    media_type_alert: "Die Datei muss der Typ der entsprechenden Mediadateien sein",
    file_stored: "Neue Datei gespeichert!",

    logout: "Abmelden",
    logout_admin: "Zurück zum Admin",
    logout_trusted_user: "Zurück zu meinem Konto",

    dashboard: "Dashboard",
    botschaften: "Persönliche Botschaften",
    checkliste: "Checkliste",
    tresor: "Tresor",
    trusted_menu: "Vertrauenspersonen",
    up: "Up",
    up_subtitle: "Dieser Bereich ist in Kürze für Sie verfügbar. Wir werden Sie über Neuigkeiten informieren.",
    support: "Support",
    support_tickets: "Support Tickets",
    profile: "Profil",
    wunsch: "Der letzte Wunsch",
    pending: 'Ausstehend',
    open: "Offen",
    closed: "Geschlossen",
    close: "Schließen",
    comments: "Bemerkungen",
    accepted: "Akzeptiert",
    rejected: "abgelehnt",
    pays: "Zahlungen",
    all: "alle",
    successful: "erfolgreich",
    amount: "Betrag",

    information: "Persönliche Daten",
    personal_information: "Persönliche Informationen",
    keys: "Schlüssel",
    account: "Zahlung",
    trusters: "Vertrauter",
    truster: "Vertrauter",
    notifications: "Benachrichtigungen",
    tap_to_edit: "Klicken um zu ändern",
    status: 'Status',
    plan_subscription: 'Mitgliedschaft',
    subscription_users: 'Abonnement-Nutzer',
    age: "Alter",
    martial_status: "Familienstand",
    verheiratet: "Verheiratet",
    single: "Ledig",
    indent_nr: "Rentenversicherungsnummer",
    address: "Adresse",
    street: "Strasse",
    house_number: "Hausnummer",
    zip: "PLZ",
    city: "Stadt",
    height: "Größe",
    spouse: "Partner",
    job: "Beruf",
    birthplace: "Geburtsort",
    gender: "Geschlecht",
    children: "Kinder",
    birthdate: "Geburtstag",
    nationality: "Nationalität",
    national_code: "Ausweis-Nr.",
    phone: "Telefonnummer",
    notifications_subtitle: "In diesem Abschnitt werden alle Ihre Nachrichten und Aktivitäten angezeigt.",
    title: "Titel",
    message: "Nachricht",
    empty_notifications: "Derzeit ist keine Nachricht für Sie vorhanden",
    account_info: "Kontoübersicht",
    current_password: "Aktuelles Passwort",
    plans: "Zahlungspläne",
    remaining_days: "Tage übrig",
    days: "Tage",
    storage: "Speicherplatz",
    task_limit: "Aufgaben Limit",
    trusted_limit: "Limit an Vertrauenspersonen",
    duration: "Dauer",
    price: "Preis",
    redeem_code_success: "Ihr Abo wurde erfolgreich aktiviert.",
    redeem_code_error: "Ihr Abo konnte nicht aktiviert werden!",
    new_ticket: "Neues Ticket",
    new_ticket_subtitle: "Bitte schreiben Sie Ihre Nachricht und senden Sie diese an die zuständige Abteilung",
    loading: "Lädt… bitte warten",
    empty_data: "Keine Support-Tickets vorhanden",
    department: "Abteilung",
    subject: "Betreff",
    last_update: "Letzte Änderung",
    error: "Fehler",
    send: "senden",
    ticket: "Ticket",
    created_at: "Erstellt am",
    new_message: "Neue Nachricht",
    tresor_subtitle: "Ein sicherer Ort für Ihre Dateien.",
    file_upload_success: "Datei erfolgreich hochgeladen!",
    my_trusted_title: "Meine Vertrauten",
    my_trusted_desc: 'In diesem Abschnitt können Sie Personen eintragen, denen Sie vertrauen. Diese Personen können dadurch jederzeit auf Ihr Profil zugreifen z. B. in einer Notfallsituation.',
    entrusted_to_me_title: "Mir Anvertraute",
    entrusted_to_me_desc: 'In diesem Abschnitt können Sie sehen, wer Ihnen vertraut und Ihnen Zugriff auf deren Profil gewährt hat. Sie können die folgende ID an Ihre Vertrauten weitergeben, um ihnen den Zugriff zu ermöglichen:',
    no_trusted_users: 'Bisher wurden noch keine Vertrauenspersonen angelegt.',
    no_entrusted_users: 'Bisher wurde Ihnen noch kein Zugriff auf ein Profil gewährt.',
    trusted_added_success: "Vertrauensperson erfolgreich hinzugefügt.",
    trusted_removed_success: "Vertrauensperson erfolgreich entfernt.",
    trusted_add_already_registered: "Bereits registrierte Person hinzufügen",
    trusted_add_unregistered: "Noch nicht registrierte Person hinzufügen",
    trusted_user_id_place_description: "Bitte geben Sie die ID der Person ein, der Sie vertrauen möchten. Die Person findet die ID in ihrem Profil unter Vertrauenspersonen > Mir Anvertraute.",
    trusted_user_id: "Vertrauensperson-ID",
    trusted_user_id_copied: "Vertrauensperson-ID kopiert!",
    trusted_user_invited: "Eingeladen",
    trusted_user_entrusted: "Zugriff erteilt",
    users: "Benutzer",
    add: "Hinzufügen",
    delete_trusted_alert: "Vertrauensperson wirklich entfernen?",
    resend_email_alert: "Soll die E-Mail an die Vertrauensperson erneut versendet werden?",
    truster_email_resent: "E-Mail an die Vertrauensperson wurde versendet",
    start_type_to_search: "Starte Eingabe zum suchen",
    privacy: "Datenschutz",
    imprint: "Impressum",
    terms: 'Allgemeine Verkaufsbedingungen',
    about_us: 'Über uns',

    token_expired: "Deine Session ist abgelaufen. Bitte melde dich erneut an!",

    male: 'Männlich',
    female: 'Weiblich',
    other_gender: 'Diverse',
    copied: 'Kopiert!',
    features: 'Merkmale',

    add_note_to_checklist: 'Notiz hinzufügen',
    update_checklist_note: 'Notiz aktualisieren',
    note: 'Notiz',

    personaldocs: 'Persönliche Unterlagen',
    documenttemps: 'Dokumentvorlagen',
    documenttemps_subtitle: 'Laden Sie hier verschiedene Dokumentvorlagen herunter.<br>Bitte beachten Sie, dass Sie für die ausfüllbaren Formulare den Adobe Acrobat Reader benötigen.',

    personaldocs_title: 'Persönliche Unterlagen',
    personaldocs_subtitle: 'Laden Sie hier Ihre persönlichen Unterlagen aller Art hoch.',

    advisor: 'alleato',
    advisor_title: 'alleato',
    advisor_subtitle: 'Die alleato assekuranzmakler GmbH, unser exklusiver Partner, ermöglicht Ihnen durch umfangreiche Rahmenverträge Zugang zu attraktiven Sonderkonditionen. Überzeugen Sie sich selbst und vergleichen Sie die Angebote gerne mit anderen Portalen wie Check24. Ein Klick auf das Logo führt Sie direkt zur Partnerseite – völlig kostenlos und unverbindlich!',
    advisor_help_text: 'Individuellen Beratungstermin vereinbaren',
    advisor_categories: {
        1: 'Sachversicherungen',
        2: 'Lebens- & Berufsunfähigkeitsversicherungen',
        3: 'Kranken- & Pflegeversicherungen',
        4: 'Ratgeber & Informationen',
    },
    go_to_advisor_partner: "Jetzt zum Versicherungspartner",
    advisorpartners_list: {
        syncro24: {
            name: 'Syncro24 – assekuradeur GmbH',
            description: 'Profitieren Sie durch die Zusammenarbeit von alleato mit Syncro24 von Top-Preisen und attraktiven Rabatten auf sämtliche angebotenen Versicherungen. Mit Syncro24 erhältst du exklusive Versicherungsprodukte, die speziell auf deine Bedürfnisse zugeschnitten sind. Wichtig: Geben Sie im Formular als Vermittler „alleato“ mit der Vermittlernummer „47055“ an, um von den Vorteilen zu profitieren.',
        },
        allvest: {
            name: 'Allvest GmbH',
            description: 'Anstatt der regulären 2 % Kosten auf Einzahlungen und laufenden Kosten von 0,6 % pro Jahr, sieht der Sondertarif 0 % auf alle Einzahlungen, bei laufenden Kosten von 0,7 % pro Jahr vor.',
        },
        continentale: {
            name: 'Continentale Versicherungsverbund',
            description: '',
        },
        ideal: {
            name: 'IDEAL Versicherung AG',
            description: '',
        },
    },

    benefit_partners: 'Vorteilspartner',
    benefit_partners_title: 'Vorteilspartner',
    benefit_partners_subtitle: 'Entdecken Sie hier unsere Partner, bei denen Sie von erstklassigen Konditionen profitieren können.',
    benefit_partners_no_coupon: "Kein Code notwendig",
    go_to_benefit_partner: "Jetzt zum Vorteilspartner",
    coupon_code_copied: "Gutscheincode kopiert!",
    benefit_partners_list: {
        juradirekt: {
            name: 'JURA DIREKT GmbH',
            description: 'Beste Preise. Beste Leistungen. Beste Beratung.',
            long_description: 'Bei JuraDirekt profitieren Sie von unschlagbaren Preisen, herausragenden Leistungen und individueller Beratung. Unser erfahrenes Team steht Ihnen jederzeit zur Verfügung, um maßgeschneiderte Lösungen für Ihre juristischen Anliegen zu finden.'
        }, saveid: {
            name: 'Safe-iD.eu',
            description: 'Erhalten Sie 20% Preisvorteil auf alle Produkte.',
            long_description: 'Nutzen Sie jetzt Ihren exklusiven Preisvorteil von 20 % auf unser gesamtes Sortiment! Entdecken Sie unsere personalisierten SOS-Armbänder und iD-Tags, die für zusätzlichen Schutz und Sicherheit sorgen.'
        }, life4style: {
            name: 'Boheme Boutiqe Events UG (haftungsbeschränkt)',
            description: 'Erhalten Sie 25 % Preisvorteil auf alle Produkte.',
            long_description: 'Sichern Sie sich 25 % Rabatt auf alle Home and Living Produkte. Gestalten Sie Ihr Zuhause mit stilvollen Möbeln, Deko und Accessoires neu – jetzt günstiger!'
        }, dsf: {
            name: 'DeinSportsfreund.de',
            description: '15 % auf Alles außer Vereinskollektionen und Sonderaktionen.',
            long_description: 'Erhalten Sie jetzt auf DeinSportsfreund.de einen sportlichen Mehrwert von 15 % auf das gesamte Sortiment! Im Angebot haben wir Sportbekleidung, Sportschuhe und Sportzubehör der Top Marken wie adidas, Nike, Puma & Hummel uvm. Ausgeschlossen sind Vereinskollektionen unserer Vereinspartner als auch Sonderaktionen.',
        }, wechselpilot: {
            name: 'Wechselpilot GmbH',
            description: 'Nie wieder stundenlang Strom- & Gasanbieter vergleichen.',
            long_description: 'Nie wieder stundenlang Strom- & Gasanbieter vergleichen, nie wieder in überteuerten Energietarifen stecken bleiben, nie wieder Kündigungsfristen verpassen. Wechselpilot vergleicht & wechselt jedes Jahr Ihren Strom- & Gasanbieter für Sie.',
        }, quarg: {
            name: 'Sanitätshaus QUARG GmbH',
            description: 'Übernahme der gesetzliche Rezeptgebühr für Sanitätsleistungen.',
            long_description: 'Profitieren Sie von den Leistungen von QUARG und genießen Sie mehr Lebensqualität und Mobilität. Wir unterstützen Sie mit Bandagen, Orthesen, Kompressionsstrümpfen, Einlagen, Diabetesversorgungen und Rehahilfsmitteln. Als Mitglied bei Geras24 übernehmen wir die gesetzliche Rezeptgebühr für Sie.',
        }, eventhotels: {
            name: 'Crusader Investments B.V.',
            description: '10% auf Zimmerbuchungen in ausgewählten Event Hotels.',
            long_description: 'Profitieren Sie von einem exklusiven Rabatt von 10 % auf Zimmerbuchungen in ausgewählten Event Hotels in Deutschland. Entdecken Sie mit dieser Kooperation Komfort, Entspannung und exzellenten Service – perfekt für Ihre nächste Geschäfts- oder Privatreise.',
        }, carclub: {
            name: 'Automobilclub', description: 'In Kürze verfügbar.'
        }, toppartner: {
            name: 'Top Partner', description: 'In Kürze verfügbar.'
        }, sportclub: {
            name: 'SportClub', description: 'In Kürze verfügbar.'
        },
    },

    expired_at: 'Abgelaufen am',

    otp_title: 'Zwei-Faktor-Authentifizierung',
    otp_description: 'Gib den 6-stelligen Sicherheitscode aus deiner Authentifizierung-App ein (z. B. Google Authenticator).',
    otp_code: 'Sicherheitscode',
    enter_otp: 'Bitte gib den 6-stelligen Sicherheitscode ein!',
    otp_confirm: 'Bestätigen',
    success_otp_enabled: 'Zweistufige Anmeldung aktiviert',
    success_otp_disabled: 'Zweistufige Anmeldung deaktiviert',

    add_user: 'Vertrauenspersonen hinzufügen',

    started: 'Warten auf Zahlung',

    send_contact_email_success: 'Vielen Dank für deine Nachricht.',

    not_activated: "Nicht aktiviert",
    activated: "Aktiv",
    your_abos: "Ihre Mitgliedschaft",
    your_abos_subtitle: "Verwalten Sie hier Ihre Mitgliedschaft.",
    your_abos_list: "Gebuchte Mitgliedschaft",
    no_abos: "Noch keine gebuchte Mitgliedschaft vorhanden. Bitte wählen Sie eine Mitgliedschaft oder geben Sie einen bereits erhalten Code ein.",
    abonnement: 'Mitgliedschaft',

    last_wish_title: 'Der letzte Wunsch',
    last_wish_desc: 'Hinterlassen Sie hier Ihren letzten Wunsch',

    no_files_uploaded: 'Bisher wurden noch keine Dateien hochgeladen.',

    meta_tags: {
        home: {
            title: 'Geras24 - Der digitale Ort zur Absicherung deines Lebens und der Familie',
            description: 'Dein digitaler Begleiter zur sicheren Verwaltung und Absicherung deines Lebens und deiner Familie. Einfach und zuverlässig alles Wichtige an einem Ort verwalten.'
        }, competitions: {
            1: {
                title: 'Füchse Duisburg Gewinnspiel - Geras24', description: 'Füchse Duisburg Gewinnspiel'
            },
            2: {
                title: 'Holstein Kiel 1. Bundesliga Gewinnspiel - Geras24', description: 'Holstein Kiel 1. Bundesliga Gewinnspiel'
            }
        }, products: {
            'der-letzte-wunsch': {
                title: 'Der letzte Wunsch - Geras24', description: 'Der letzte Wunsch'
            }, 'persoenliche-botschaft': {
                title: 'Persönliche Botschaften - Geras24', description: 'Persönliche Botschaften'
            }, checkliste: {
                title: 'Checkliste - Geras24', description: 'Checkliste'
            }, tresor: {
                title: 'Digitale Tresor - Geras24', description: 'Tresor'
            }, vorteilspartner: {
                title: 'Vorteilspartner - Geras24', description: 'Vorteilspartner'
            },
        }, impressum: {
            title: 'Impressum - Geras24', description: 'Impressum'
        }, datenschutz: {
            title: 'Datenschutz - Geras24', description: 'Datenschutz'
        }, seller_terms: {
            title: 'Allgemeine Verkaufsbedingungen - Geras24', description: 'Allgemeine Verkaufsbedingungen'
        }, about: {
            title: 'Über uns - Geras24', description: 'Über uns'
        }, contact_us: {
            title: 'Kontakt - Geras24', description: 'Kontakt'
        }, faq: {
            title: 'Hilfe - Geras24', description: 'Hilfe'
        }, notfallbox: {
            title: 'Notfallbox - Geras24', description: 'Geras24 Notfallbox'
        }, dashboard: {
            title: 'Dashboard - Geras24', support: {
                title: 'Support - Geras24',
            }, checklist: {
                title: 'Checkliste - Geras24',
            }, tresor: {
                title: 'Tresor - Geras24',
            }, profile: {
                title: 'Profil - Geras24',
            }, trusted_users: {
                title: 'Vertrauenspersonen - Geras24',
            }, personal_messages: {
                title: 'Persönliche Botschaften - Geras24',
            }, personal_documents: {
                title: 'Persönliche Unterlagen - Geras24',
            }, advisor: {
                title: 'alleato - Geras24',
            }, benefit_partners: {
                title: 'Vorteilspartner - Geras24',
            }, last_wish: {
                title: 'Der letzte Wunsch - Geras24',
            }, document_templates: {
                title: 'Dokumentvorlagen - Geras24',
            }
        }
    },

    texts: {
        home: {
            title: 'Der digitale Ort zur Absicherung deines Lebens und der Familie', register_now_for_free: 'Jetzt kostenlos registrieren', reasons: {
                reason1: 'Innovative digitale Plattform für umfassende Familienabsicherung.',
                reason2: 'Lebenslange Begleitung von der Vorsorge bis zur Nachsorge – Wir sind für dich und deine Lieben da.',
                reason3: 'Wir kümmern uns nicht nur um dich, sondern auch um deine Familie und engsten Freunde.',
            }, usage_points: {
                title: 'Warum du Geras24 nutzen solltest', subtitle: 'Unabhängige Statistiken zeigen, warum Geras24 die beste Wahl für dich und deine Familie ist.', default_button_text: 'Sicherheit im ...', point1: {
                    button_text: 'Brandfall',
                    title: 'Sicherheit im Brandfall',
                    description: 'Jedes Jahr verlieren Menschen in Deutschland und auf der ganzen Welt durch Wohnungsbrände wertvolle Erinnerungen und wichtige Unterlagen. Videos aus der Kindheit, Familienfotos oder persönliche Andenken, die oft von unschätzbarem emotionalem Wert sind, werden unwiederbringlich zerstört. Doch neben diesen emotionalen Verlusten können auch gravierende praktische Probleme entstehen, wenn wichtige Dokumente, wie notarielle Unterlagen oder Verträge, in den Flammen verloren gehen. Der Verlust solcher Dokumente könnte beispielsweise zu rechtlichen und finanziellen Schwierigkeiten führen, wenn Nachweise über Eigentum oder Vereinbarungen fehlen. Diese Beispiele verdeutlichen, wie wichtig es ist, essentielle Unterlagen sicher aufzubewahren.'
                }, point2: {
                    button_text: 'Hochwasserfall',
                    title: 'Sicherheit im Hochwasserfall',
                    description: 'Jedes Jahr sind Menschen in Deutschland und weltweit von den verheerenden Folgen von Hochwasser betroffen. Der Klimawandel führt zu immer häufigeren und intensiveren Starkregenereignissen, wodurch das Risiko für Überschwemmungen wächst. Diese Naturkatastrophen können nicht nur Gebäude und Infrastruktur schwer beschädigen, sondern auch wertvolle persönliche Besitztümer und wichtige Dokumente gefährden. Beispielsweise können wichtige Unterlagen wie medizinische Befunde oder Immobilienpapiere durch das Wasser unleserlich oder unbrauchbar werden. Dies kann zu Schwierigkeiten bei der Wiederbeschaffung von Dokumenten oder der Klärung von Ansprüchen führen. Angesichts der zunehmenden Gefahr von Hochwasser ist es daher entscheidend, Maßnahmen zum Schutz und zur sicheren Aufbewahrung wichtiger Unterlagen zu ergreifen.'
                }, point3: {
                    button_text: 'Notfall',
                    title: 'Sicherheit im Notfall',
                    description: 'Die meisten Unfälle passieren im eigenen Zuhause - Tendenz steigend, weshalb es entscheidend ist, dass die medizinische Vorsorge gut organisiert ist. Patientenverfügungen, medizinische Anweisungen und Versicherungsunterlagen sollten an einem sicheren Ort aufbewahrt werden. Viele Menschen denken selten an den Notfall und haben keine klaren Anweisungen hinterlegt. Dies kann für Angehörige äußerst belastend sein, wenn sie im Ernstfall nicht wissen, ob solche Dokumente vorhanden sind oder wo sie zu finden sind. Eine gute Organisation dieser wichtigen Unterlagen kann nicht nur schnelle Entscheidungen erleichtern, sondern auch dazu beitragen, die Belastung für die Familie zu minimieren.'
                }
            }, customer_feedback: {
                title: 'Was unsere Kunden über uns denken', subtitle: 'Wir sind stolz auf unsere Zahlen und die Zufriedenheit unserer Kunden.',
            }, statistics: {
                title: 'Warum du auf Geras24 vertrauen kannst',
                subtitle: 'Die Geras24-Community wächst stetig und rettet somit aktiv Leben.',
                statistic_members: 'Aktive Mitglieder',
                statistic_emergency_folders: 'Verwendete Notfallordner',
                statistic_emergency_cards: 'Verwendete Notfallkarten'
            }, pricing: {
                title: 'Unsere Preise',
                subtitle: 'Im Folgenden findest du unsere verschiedenen Modelle, um Mitglied zu werden. Sichere dir jetzt ein Stück Sicherheit für dein Leben.',
                register_book_now: 'Jetzt buchen',
                mostly_chosen: 'Am meisten gewählt',
                yes: 'Ja',
                plans: {
                    family_carefree: {
                        title: 'Family Sorglos', subtitle: 'Volle Kostenkontrolle mit maximalem Umfang.'
                    }, lifetime: {
                        title: 'Ein Leben Lang Sorglos', subtitle: 'Von Anfang bis Ende die beste Entscheidung.'
                    }
                },
                periods: {
                    monthly: 'monatlich', yearly: 'jährlich', lifetime: 'einmalig'
                },
                features: {
                    seven_days_trial: '7 Tage kostenlose Testphase',
                    storage: 'Speicherplatz',
                    checklist: 'Checkliste',
                    last_wish: 'Der letzte Wunsch',
                    personal_message: 'Persönliche Botschaften',
                    personal_documents: 'Persönliche Unterlagen',
                    document_templates: 'Dokumentvorlagen',
                    vault: 'Digitale Tresor',
                    up: 'Up',
                }
            }, security: {
                dsgvo: 'DSGVO', data_store: 'Datenspeicher in Deutschland', ssl: 'SSL-Verschlüsselung'
            }, in_cooperation_with: 'In Zusammenarbeit mit', features: {
                personal_message: {
                    title: 'Persönliche Botschaften', description: 'Verfasse und hinterlege persönliche Botschaften für deine Liebsten.'
                }, checklist: {
                    title: 'Checkliste', description: 'Mit unserer Checkliste behältst du in allen Lebenslagen den Überblick.'
                }, vault: {
                    title: 'Digitale Tresor', description: 'Lade wichtige Dokumente hoch und erhalte alle Unterlagen von unseren Partnern.'
                }, last_wish: {
                    title: 'Der letzte Wunsch', description: 'Halte deinen letzten Wunsch so detailliert wie möglich fest.'
                }, benefit_partners: {
                    title: 'Vorteilspartner', description: 'Erhalte exklusive Preisvorteile auf Produkte & Dienstleistungen bei unseren Vorteilspartnern.'
                }, insurance: {
                    title: 'Persönliche Absicherung', description: 'Unschlagbare Versicherungen mit Bestleistungen von unserem Partner alleato.'
                }
            }, reviews: {
                loading_error: 'Die Bewertungen konnten nicht geladen werden.',
            }
        },
        competitions: {
            legal_title: 'Teilnahmebedingungen',
            legal: 'Es gelten die unten aufgeführten Teilnahmebedingungen. Bereits registriert? Jetzt anmelden und trotzdem teilnehmen!',
            register_and_participate: 'Jetzt registrieren und teilnehmen',
            participate: 'Jetzt teilnehmen',
            participated_title: 'Vielen Dank für deine Teilnahme!',
            participated_description: 'Vielen Dank für deine Teilnahme am Gewinnspiel. Wir haben deine Daten erhalten und wünschen viel Glück! Im Falles eines Gewinns werden wir uns noch einmal bei dir melden.',
            1: {
                title: 'Füchse Duisburg Gewinnspiel 🏒',
                subtitle: 'Jetzt mitmachen und 2 VIP-Tickets inklusive Pressekonferenz und Meet & Greet mit den Spielern für den 30.01.2025 gewinnen! 🦊🏒',
                description: 'Nutze jetzt die Chance und gewinne 2 VIP-Tickets inklusive Pressekonferenz und Meet & Greet mit den Spielern. Der Preis wird zur Verfügung gestellt von Geras24 und unserem Partner alleato assekuranzmakler GmbH. Jetzt teilnehmen und gewinnen. Das Team von Geras24 wünscht viel Glück!',
                rules: {
                    rule1: '<strong>Teilnahmezeitraum:</strong> 24.01.2025 – 30.01.2025',
                    rule2: '<strong>Gewinn:</strong> 2 VIP-Tickets inkl. Pressekonferenz und Meet & Greet',
                },
            },
            2: {
                title: 'Holstein Kiel 1. Bundesliga Gewinnspiel ⚽',
                subtitle: 'Jetzt mitmachen und 1 x 2 VIP-Tickets inklusive Übernachtung gewinnen!',
                description: 'Nutze jetzt die Chance und gewinne 1 x 2 VIP-Tickets für ein Heimspiel von Holstein Kiel in der 1. Bundesliga – inklusive Übernachtung! Der Preis wird zur Verfügung gestellt von unserem Partner alleato assekuranzmakler GmbH. Jetzt teilnehmen und gewinnen. Das Team von Geras24 wünscht viel Glück!',
                rules: {
                    rule1: '<strong>Teilnahmezeitraum:</strong> 11.11.2024 – 24.12.2024',
                    rule2: '<strong>Gewinn:</strong> 2 VIP-Tickets inkl. Übernachtung',
                },
            },
        },
        products: {
            checklist: {
                title: 'Die Checkliste nach einem Todesfall',
                description: 'Stell dir vor, ein geliebter Mensch ist plötzlich verstorben und du stehst als Angehöriger vor der Aufgabe, alle Angelegenheiten zu regeln. Doch keine Sorge, wir haben eine kostenlose Checkliste für dich erstellt, die dir Schritt für Schritt zeigt, was nach einem Todesfall zu erledigen ist. Von der Kündigung von Verträgen bis hin zur Auszahlung von Lebensversicherungen - wir haben alles im Blick. Als Mitglied bei Geras24 kannst du dich darauf verlassen, dass wir dir diese mühsame Arbeit abnehmen. Denn mit uns kannst du bereits zu Lebzeiten alles Wichtige regeln. Lass uns dir helfen und werde noch heute Mitglied!',
            }, last_wish: {
                title: 'Der letzte Wunsch',
                description: 'Jeder hat für die Zeit nach seinem Tod eine andere Vorstellung davon, was er als richtig und schön empfindet. Viele können sich eine klassische Beerdigung nicht vorstellen. Sie wollen keine trauernden Gäste, sondern Freunde und liebe Menschen um sich wissen, die an die schönen Zeiten denken. Nur du bist in der Lage, schon zu Lebzeiten zu bestimmen, was dir wichtig ist. Halte deinen letzten Wunsch so detailliert wie möglich fest und hinterlege ihn auf der Plattform von Geras24. So erleichterst du deinen Liebsten die ohnehin schwere Zeit nach deinem Tod. Werde noch heute ein Mitglied der Geras24 Gemeinschaft und halte fest, wie dein letzter Wunsch genau aussieht.',
            }, personal_message: {
                title: 'Persönliche Botschaften',
                description: 'Eine ganz besondere Funktion bei Geras24 sind die persönlichen Botschaften. Hier hast du die Möglichkeit, deinen Liebsten Videos, Sprachnachrichten oder Briefe zukommen zu lassen. So bleibst du ihnen auch über den Tod hinaus erhalten und schaffst wertvolle Momente.',
            }, digital_safe: {
                title: 'Der digitale Tresor',
                description: 'Eines der Hauptprodukte von Geras24 ist die Möglichkeit, alle wichtigen Dokumente sicher hochzuladen. Hier findest du alles, was du für deine tägliche Buchhaltung benötigst, wie zum Beispiel Kontoauszüge, Versicherungspolicen oder Arztrechnungen. Doch nicht nur für den aktuellen Bedarf ist diese Funktion nützlich - du kannst auch alle Dokumente sicher abspeichern, um für den Fall eines schweren Unfalls oder im Todesfall sicherzustellen, dass deine Hinterbliebenen auf alle wichtigen Unterlagen zugreifen können. Registriere dich jetzt kostenlos und nutze diese wertvolle Funktion!',
            }, benefit_partners: {
                title: 'Vorteilspartner',
                description: 'Geras24 bietet dir nicht nur die Möglichkeit, deine Angelegenheiten zu regeln, sondern auch von exklusiven Rabatten bei unseren Vorteilspartnern zu profitieren. Erhalte Rabatte auf Produkte und Dienstleistungen, die dir das Leben erleichtern. Werde noch heute Mitglied und sichere dir die besten Konditionen!',
            },
        }, footer: {
            personal_message: 'Persönliche Botschaften',
            checklist: 'Checkliste',
            vault: 'Digitale Tresor',
            last_wish: 'Der letzte Wunsch',
            emergency_box: 'Notfallbox',
            benefit_partners: 'Vorteilspartner',
            up: 'Up',
            help: 'Hilfe',
            contact: 'Kontakt',
            geras24_shop: 'Geras24 Shop',
            all_rights_reserved: 'Alle Rechte vorbehalten.',
            privacy: "Datenschutz",
            imprint: "Impressum",
            terms: 'Allgemeine Verkaufsbedingungen',
            about_us: 'Über uns',
        }
    }
}
